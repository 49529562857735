export default {
    user: {
        settings: {
            social: {
                title: 'Social media and links',
                description: 'Instagram, email etc.',
                choose_link_type: 'Choose type',
            },
        },
    },
    placeWizard: {
        steps: {
            selectPlace: {
                title: 'Business',
                place_found:
                    'A business with a similar name already exists. Is that the one you meant?',
                places_found:
                    '{n} businesses with similar names already exist. Are those the ones you meant?',
                none_skip: 'No, none of those',
            },
            premiumOwner: {
                title: 'Premium',
                description: 'Are you a Thatsup Premium client?',
                what_is: 'What is',
                want_info: 'Wants information',
            },
            description: {
                title: 'Description',
                textarea_placeholder_new: 'Describe the business. Max 500 characters.',
                textarea_placeholder_edit: 'Suggest changes...',
                textarea_label: 'Description',
                current_description_label: 'Current description',
            },
            openingHoursOverview: {
                title: 'Opening hours',
                opening_hours: 'Fill in the opening hours',
                opening_hours_seasonal: 'Seasonal opening hours etc.',
                no_opening_hours: 'No opening hours',
                always_open: 'Always open',
                no_opening_hours_confirm: 'This will reset your changes to the opening hours',

                opening_hours_seasonal_title: 'Indicate which months the business is open.',
            },
            problem: {
                title: 'Problem',
                closed: "The business has closed, moved or doesn't exist",
                duplicate: 'This is a duplicate of another business listed on Thatsup',
                inappropriate: 'This business is inappropriate',

                not_closed: 'This business is not closed',
                permanently_closed: 'It is permanently closed',
                temporarily_closed: 'It is temporarily closed',
                moved: 'It moved',
                nonexistent: 'It is nonexistent',

                other: 'Annat',

                duplicate_textarea_placeholder: 'Link to duplicate on Thatsup',
                inappropriate_textarea_placeholder:
                    'Describe why is this business inappropriate...',
                other_textarea_placeholder: 'Describe the problem...',
            },
            general: {
                title: 'General information',
                no_website_label: 'Has no website',
            },
            editCategories: {
                title: 'Categories',
                addButton: 'Add a category',
            },
            addCategories: {
                title: 'Categories',
                categories_label: 'Categories',
                add_category_label: 'Add a category',
                add_category_placeholder: 'Pizza, Café, Hair salon etc.',
                add_category_no_results_text:
                    "The category doesn't exist.Try searching under another category.",
            },
            address: {
                title: 'Location of the business',
                autocomplete_placeholder: '221B Baker Street, Westminster etc...',
                autocomplete_helper:
                    "Don't know the exact address? Provide the area or nearby street name or select a",
                autocomplete_helper_manual_position_link: 'location manually',
                map_position: 'Position on the map.',
                city: 'City',
                city_other: 'Other city',
            },
            summary: {
                title: 'Summary',
                nothing_changed: "You have'nt changed anything, yet.",
                removed: 'Removed',
            },
            userInfo: {
                title: 'Other information',
                description:
                    'To enable us to process your contribution more quickly, please provide some information about yourself.',
                name_label: 'Your name',
                phone_label: 'Your telephone number',
                email_label: 'Your email address',
                other_information_label: 'Other information',
                create_account_description: 'Create an account at Thatsup with your email address',
            },
            overview: {
                title: 'Overview',
                general: {
                    title: 'General information',
                    description: 'Telephone, website etc.',
                },
                openingHoursOverview: {
                    title: 'Opening hours',
                    description: 'When is the business open?',
                },
                address: {
                    title: 'Location',
                    description: 'Address and location on the map.',
                },
                editCategories: {
                    title: 'Categories',
                    description: 'Price category, type of cuisine etc.',
                },
                description: {
                    title: 'Description',
                    description: 'Suggest changes.',
                },
                problem: {
                    title: 'Problem',
                    description: 'The business has closed etc.',
                },
            },
            review: {
                title: 'Review',
                intro_title: 'Be the first to write a review {name}!',
                button_yes: 'Write a review',
                button_no: 'No thanks',
                save_review: 'Save your review',
                rating_text_placeholder: 'We want to know what you think!',
                body_placeholder: 'Write a review',
                rating_1: 'Total disaster!',
                rating_2: 'Not great...',
                rating_3: 'Ok!',
                rating_4: 'I like it!',
                rating_5: 'Wow, this place is awesome!',
            },
            thankYou: {
                title: 'Thank you!',
                titleWithName: 'Thank you {name}!',
                subTitleNewPlace:
                    "We'll check your recommendation and publish it as soon as we can.",
                subTitleEditPlace: 'Thank you for helping us make Thatsup even better!',
                descriptionEditPlace:
                    'We need the help of lovely people like you to ensure our information is as accurate as possible.',
                buttonText: "You're welcome!",
                add_new_place: 'Add another business',
            },
        },
        start: {
            title: 'Add a business',
            title_edit: 'Edit {placeName}',
            description:
                'Do you own the business or are you a user who wants to help us improve Thatsup?',
            owner: 'Owner',
            user: 'User',
        },
        place: {
            name_label: 'Name of the business',
            website_label: 'Website',
            phone_label: 'Telephone',
            facebook_label: 'Link to Facebook page',
            instagram_label: 'Link to Instagram',
            address_label: 'Address',
        },
        common: {
            voluntary: 'Optional',
            closed: 'Closed',
            max: 'Max',
            yes: 'Yes',
            no: 'No',
            not_sure: 'Not sure',
            summary: 'Summary',
        },
        action: {
            next: 'Next',
            prev: 'Previous',
            done: 'Done',
            finish: 'Finish',
            update_place: 'Update business',
            loading: 'Loading...',
            updating: 'Updating...',
            creating: 'Creating...',
            skip: 'Skip',
        },
    },

    internal_ads: {
        webshop: {
            description:
                'Discover our merch, with inspiring quotes from the restaurant environment.',
        },
        community: {
            reviews: {
                heading: 'Review and comment!',
                cta_label: 'Create an account',
                list_items: ['Create & share lists', 'Write reviews', 'Completely free', 'No ads'],
            },
            ratings: {
                heading: 'Review your experiences!',
                cta_label: 'Create an account',
                list_items: ['Create & share lists', 'Write reviews', 'Completely free', 'No ads'],
            },
            lists: {
                heading: 'Create lists with your favorites!',
                cta_label: 'Create an account',
                list_items: ['Create & share lists', 'Write reviews', 'Completely free', 'No ads'],
            },
            reactions: {
                heading: 'Express yourself in more ways!',
                cta_label: 'Create an account',
                list_items: ['Create & share lists', 'Write reviews', 'Completely free', 'No ads'],
            },
            signup: {
                heading: 'Sign up and experience more!',
                cta_label: 'Create an account',
                list_items: ['Create & share lists', 'Write reviews', 'Completely free', 'No ads'],
            },
        },
    },

    placeList: {
        edit: {
            title: 'Edit',
            name_label: 'List name',
            description_label: 'List description',
            public_label: 'Public list',
        },
        filters: {
            city: 'City',
            category: 'Category',
        },
        my_rating: 'My rating',
        user_rating: '{possessiveFirstName} rating',
        paging_label: '{from}-{to} of {total}',
        my_lists: 'My lists',
        user_lists: '{possessiveFirstName} lists',
        place_search_placeholder: 'Find business...',
        user_search_placeholder: 'Find user...',
        invite_user: 'Invite user',
        cancel: 'Cancel',
        save: 'Save',
        delete_list: 'Delete list',
        delete_list_confirm: 'Are you sure you want to delete this list? This cannot be undone.',
        follow_list: 'Follow list',
        following_list: 'Following list',
        unfollow_list: 'Unfollow',
        unfollow_list_confirm: 'Do you want to unfollow this list?',
        creator_create_date: 'Created the list {dateAdded}',
        creator: 'Creator',
        last_updated: 'Last updated {dateUpdated}',
        list_by: 'By',
        remove_owner: 'Remove',
        remove_owner_self: 'Leave list',
        remove_owner_self_confirm:
            'Are you sure you want to leave this list? This cannot be undone and you will no longer be able to make updates to the list.',
        list_empty: 'The list is empty',
        common: {
            no: 'No',
            edit: 'Edit',
            delete: 'Delete',
            share: 'Share',
            report: 'Report',
        },
        place_new: 'New!',
        place_closed: 'Closed',
        place_add_new: 'Add new',
        list_create: 'Create list',
        list_edit: 'Edit list',
        list_add_remove: 'Add/Remove',
        list_users: 'Users',
        map: 'Map',
        tooltip: {
            public_switch:
                'A public list is accessible to other visitors at various places on the site, whilst a non-public list can’t be found by other visitors if you don’t share the specific link with them.',
        },
        notes_input_placeholder: 'Your notes...',
    },

    authForm: {
        continue_with_facebook: 'Continue with Facebook',
        continue_with_google: 'Continue with Google',
        continue_with_email: 'Continue with e-mail',

        email: 'Email',
        first_name: 'First name',
        last_name: 'Last name',
        password: 'Password',
        new_password: 'New password',
        confirm_password: 'Repeat password',
        email_suggestion: 'Is the email address correctly spelled?',

        log_in: 'Log in',

        continue: 'Continue',
        send: 'Send',
        send_again: 'Send again',
        send_instructions: 'Send instructions',

        or: 'Or',

        back: 'Back',

        legal: 'By signing up you agree to Thatsup’s <a target="_blank" href="{terms}">Terms of Service</a> and <a target="_blank" href="{privacy}">Privacy Policy</a>.',

        forgot_password_question: 'Forgot your password?',
        restore_password: 'Recover password.',
        forgot_password_sent: 'Instructions have been sent to {email}!',

        not_you_question: 'Not you?',
        log_out: 'Log out.',

        welcome_user: 'Welcome {name}!',
        profile_link: 'To your profile',
    },

    userPoll: {
        alternatives_title: 'Nominees',
        vote_confirm_title: 'Do you want to vote on:',
        vote_delete_confirm: 'Do you want to remove your vote on {alternative}?',
        vote_confirm_yes: 'Yes, vote!',
        vote_confirm_no: 'No, pick another',
        poll_not_started_title: 'Voting starts',
        poll_not_started_date: '{date}',
        poll_has_ended_title: 'Voting ended',
        poll_has_ended_date: '{date}',
        vote_not_verified_description:
            'In order for your vote to get counted you must verify your account before the end of the poll.',
        verify_account: 'Verify account.',
        voted_title: 'Thank you for voting!',
        voted_description: 'Voting ends {endDate} and a winner is announced shortly thereafter.',
        search_alternatives_label: 'Search nominees',
        search_placeholder: 'Search...',
        vote_cancel: 'Change vote',
        winner: 'Winner',
        share: 'Share your vote',
    },

    review: {
        rating_text_placeholder: 'We want to know what you think!',
        rating_1: 'Total disaster!',
        rating_2: 'Not great...',
        rating_3: 'Ok!',
        rating_4: 'I like it!',
        rating_5: 'Wow, this place is awesome!',

        thank_you_title: 'Thank you for your review!',
        thank_you_title_rating: 'Thank you for your rating!',
        add_review: 'Add review',
        write_new: 'Write new',
        update_review: 'Update review',
        write_a_review: 'Write a review',
        autosaved_review: 'Autosaved review',

        upload_photo_button: 'Upload photo',

        content_reminder:
            "Remember that it's important to write an honest review. Please refrain from reviewing if you are connected in any way to this business.",
        content_reminder_honesty_pays: 'Honesty pays!',
        see_our_guidelines_here: 'Read our guidelines here.',

        image_caption_placeholder: 'Add a caption...',
        image_delete_confirm: 'Do you want to delete this image?',

        index: {
            title: 'What are we reviewing?',
            search_placeholder: 'Search business...',
            finding_location: 'Locating your position...',
            finding_nearby_businesses: 'Finding nearby businesses...',
            nearby_businesses: 'Nearby businesses',
        },
    },

    PlaceRateBox: {
        my_rating: 'My rating',
        write_review: 'Write review',
        add_review: 'Add review',
        view_review: 'View review',
        add_photos: 'Add photos',
        delete_rating: 'Remove rating',
        rate_cta: 'Add rating',
        rating_saved: 'Your rating is saved!',
    },

    SearchAutocomplete: {
        begin_cta: 'Start searching',
        term_label: 'What are you looking for?',
        term_placeholder: 'Guides, categories, businesses etc...',
        term_placeholder_small: 'What are you looking for?',
        location_label: 'Where?',
        location_placeholder: 'District, address...',
        near_me_label: 'Near me',
        near_me_hitwords: [
            'near me',
            'close to me',
            'close to my position',
            'near my position',
            'my location',
            'near my location',
            'close to my location',
        ],
        guide_subtitle: 'Guide',
        groups: {
            history: 'Previous searches',
            recommendations: 'Recommended',
        },
        term_catch_all_label: 'Show all results for “{term}”',
        term_catch_all_label_more: 'Show more results for “{term}”',
    },

    userMessageFloater: {
        try_me_cta: "🤖 I'm Thatsup's chat bot – try me out!",
    },

    explore: {
        sorting: {
            label: 'Sort by',
            relevance: 'Relevance',
            rating: 'Rating',
            alphabetical: 'A-Z',
            distance: 'Distance',
            review_count: 'No. of reviews',
        },
        filter: {
            label: 'Filter',
            distance_label: 'Distance',
            distance_number: '{number} m',
            open_label: 'Show filter',
            close_label: 'Hide filter',
            apply_label: 'Apply',
        },
        place: {
            call_cta: 'Call',
            website_cta: 'Website',
            read_more_cta: 'Read more',
        },
        map: {
            my_location_label: 'My location',
            search_in_area_label: 'Search in area',
            expand: 'Expand map',
            contract: 'Shrink map',
            show_map: 'Show map',
        },
    },
    pagination: {
        next: 'Next',
        previous: 'Previous',
        next_page: 'Next page',
        previous_page: 'Previous page',
        from_to: '{from}-{to}',
        from_to_total: '{from}-{to} of {total}',
        back: 'Back',
        arrow_right: 'Arrow right',
        arrow_left: 'Arrow left',
    },

    elements: {
        user_action: {
            user_reviewed: 'Reviewed a business',
            place_list_updated: 'Updated a list',
            place_list_created: 'Created a list',
        },
    },
    common: {
        show_more: 'Show more',
        show_more_text: 'Show more',
        show_all: 'Show all',
        back: 'Back',
        cancel: 'Cancel',
        website: 'Website',
        phone_number: 'Phone',
        reviews: 'Reviews',
        add_link: 'Add link',
        publish: 'Publish',
        settings: 'Settings',
        notifications_short: 'Notifications',
        notifications: 'Notifications',
        close: 'Close',
        save: 'Save',
        login: 'Log in',
        logout: 'Log out',

        confirm: {
            title: 'Are you sure?',
            yes: 'Yes',
            no: 'No',
            cancel: 'Cancel',
        },

        dirty_confirm: {
            title: 'You have unsaved changes',
            description: 'Are you sure you want to leave without saving?',
            stay: 'Stay',
            leave: 'Leave',
        },
    },
    place: {
        sponsored: 'Sponsored',
        website_label: 'Website',
    },
    placeAutocomplete: {
        placeholder: 'Search business...',
    },
    popup: {
        userPlaceInteraction: {
            visited: {
                title: 'Has been here',
            },
            'want-to-visit': {
                title: 'Want to visit',
            },
        },
        reviewReaction: {
            title: 'Reactions',
        },
    },

    notifications: {
        show_all: 'Show all notifications',
    },

    mobileUserMenu: {
        user_profile: 'Profile',
        user_lists: 'Lists',
        user_messages: 'Messages',
        user_feed: 'Feed',
    },

    articleSuggestion: {
        title: 'Create an event',
        name_label: 'Name of the event',
        name_placeholder: 'Name or headline of the event...',
        artwork_label: 'Artwork',
        artwork_upload_button: 'Upload an image',
        artwork_byline_label: 'Byline',
        artwork_byline_description: 'Who took the photo or where it comes from',
        artwork_copyright_waiver:
            'I certify that I have the right to publish this image and understand that I am responsible for its publication. Thatsup bears no responsibility for any copyright violations.',
        dates_label: 'When?',
        dates_description: 'You can choose one or more dates',
        date_and_x_more_dates: '{date} and {count} more',
        location_label: 'Where?',
        location_placeholder: 'Place or address...',
        description_label: 'Description',
        description_placeholder: 'Describe the event...',
        category_label: 'Category',
        category_placeholder: 'Choose a category...',
        website_label: 'Official website',
        website_placeholder: 'Link to the event...',
        ticket_url_label: 'Ticket link',
        ticket_url_placeholder: 'Link to tickets...',
        ticket_price_label: 'Ticket price',
        ticket_price_placeholder: 'Price of tickets...',
        facebook_url_label: 'Facebook event',
        facebook_url_placeholder: 'Link to Facebook event...',
        email_label: 'Your email address',
        email_placeholder: 'Your email address...',
        is_organizer_label: 'I am the organizer',
        organizer_name_label: 'Your name',
        organizer_name_placeholder: 'Your name...',
        organizer_phone_label: 'Your phone number',
        organizer_phone_placeholder: 'Your phone number...',
        optional_label: 'Optional',
        submit_button: 'Submit',
        other_information_label: 'Other information',
        other_information_placeholder: 'Other information...',

        thank_you_title: 'Thank you for your tip 🌟',
        thank_you_description:
            'Thank you for your tip! We always strive to improve, and your engagement means a lot to us. We go through all tips as soon as we can, but unfortunately, we may not always be able to respond due to the high volume of tips we receive. We hope you understand this and look forward to receiving more tips from you in the future.',
        to_home: 'Back to home',
        write_new: 'Write new',
    },

    datepicker: {
        firstDayOfWeek: 0,
        dayNames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    },
}
