import axios from 'axios'
import { flatMap, flatten as lFlatten, isEqual } from 'lodash-es'
import { flatten } from 'lodash-es/array'
import { monthNames } from '../../../../es6/src/modules/locale/locale.js'
import { useUserStore } from '@/store/user.js'
import http from '@utils/http.js'

export default {
    emits: ['next', 'previous', 'goToStep'],
    props: ['title', 'type', 'userType', 'modelValue', 'originalPlace'],
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        isLoggedIn() {
            const userStore = useUserStore()
            return userStore.isLoggedIn
        },
        value() {
            return this.modelValue
        },
    },
    methods: {
        scrollUp() {
            // Timeout should be same as the slide-transition duration
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 350)
        },
        next() {
            if (this.type === 'edit') {
                this.goToStep('overview')
            } else {
                this.$emit('next')
            }

            this.scrollUp()
        },
        month(id) {
            return monthNames.value.long[id]
        },
        prev() {
            this.$emit('prev')

            this.scrollUp()
        },
        goToStep(stepName) {
            this.$emit('goToStep', stepName)
        },
        isOwner() {
            return this.userType === 'owner'
        },
        clearAll() {
            // Clear all data and begin from beginning
            window.location = window.location.pathname
        },
        persistPlace() {
            this.isLoading = true
            let uri = 'api/place-suggestion'
            let method = 'put'
            let data = JSON.parse(JSON.stringify(this.value))
            const uuid = this.value.uuid

            if (uuid) {
                uri += '/' + uuid
                method = 'put'
            } else {
                method = 'post'
            }

            const openingHours = data.openingHours

            if (data.city) {
                data.cityId = this.value.city.id
            }

            if (isEqual(this.value.openingHours, this.originalPlace.openingHours)) {
                data.openingHours = null
            }
            // Flatten and filter out items that has not been changed
            // else {
            //     this.value.openingHours = flatten(this.value.openingHours.map(day => day.hours)).filter(hour => {
            //             return hour?.removed || hour?.changed || !hour?.id
            //         })
            // }

            data.addedCategories = this.value.categories.filter((i) => i.new)
            data.removedCategories = this.value.categories.filter((i) => i.removed)

            let removedOpeningHours = lFlatten(openingHours)
            removedOpeningHours = flatMap(openingHours, (d) => d.hours)
                .filter((d) => d.removed || (d.changed && d.id))
                .map((d) => d.id)

            data.removedOpeningHours = removedOpeningHours

            console.log(this.value)

            return http[method]('/' + uri, {
                captcha: this.value.captcha,
                place: data,
            })
                .then((res) => {
                    if (res.data.data?.link) {
                        this.value.permalink = res.data.data.link
                    }

                    this.value.persisted = true
                    this.isLoading = false
                    this.creating = false

                    console.log(this.value)

                    return res.data
                })
                .catch((err) => {
                    this.isLoading = false
                })
                .finally((res) => {
                    this.isLoading = false
                })
        },
    },
}
