<template>
    <div class="p-4 text-center">
        <img class="tw-mx-auto" src="/media/img/place/added.svg" width="130" height="119" alt="" />

        <h2 class="pt-3" v-if="isLoggedIn">
            {{
                $t('placeWizard.steps.thankYou.titleWithName', {
                    name: this.userStore.user.firstName,
                })
            }}
        </h2>
        <h2 class="pt-3" v-else>
            {{ $t('placeWizard.steps.thankYou.title') }}
        </h2>

        <template v-if="type === 'edit'">
            <p>{{ $t('placeWizard.steps.thankYou.subTitleEditPlace') }}</p>
            <p>{{ $t('placeWizard.steps.thankYou.descriptionEditPlace') }}</p>
        </template>
        <template v-else>
            <p>{{ $t('placeWizard.steps.thankYou.subTitleNewPlace') }}</p>
        </template>

        <a
            :href="leaveLink"
            class="flat-button flat-button--blue flat-button--large flat-button--full mt-3"
        >
            {{ $t('placeWizard.steps.thankYou.buttonText') }}
        </a>
        <a v-if="type !== 'edit'" class="mt-2 block italic" @click="clearAll">
            {{ $t('placeWizard.steps.thankYou.add_new_place') }}
        </a>
    </div>
</template>

<script>
import BaseStepMixin from '../Shared/BaseStepMixin'
import { useUserStore } from '@store/user.js'

export default {
    mixins: [BaseStepMixin],
    setup() {
        const userStore = useUserStore()

        return { userStore }
    },
    computed: {
        leaveLink() {
            if (this.value.permalink) {
                return this.value.permalink
            } else {
                return '/'
            }
        },
    },
}
</script>

<style>
.wizard-components {
    padding-top: 0;
}
</style>

<style scoped>
h2 {
    font-size: 20px;
    font-weight: 600;
}
</style>
